// import { AlertTitle } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { toast } from "react-toastify";

import Loader from "../components/Loader";
import { __API_URL__ } from "../SERVER_URL";
import { AlertStatus } from "../servicemanager/enums";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const tokenSaved = localStorage["token"];
  const storedOtpState = localStorage.getItem("otpState");
  const [isLogin, setIsLogin] = useState(
    tokenSaved == undefined ? false : ["otp_verification", "sending_otp", "password", "Remember_Device"].includes(storedOtpState) ? false : true);
  const [token, setToken] = useState(
    localStorage.getItem("token") == undefined
      ? null
      : localStorage.getItem("token")
  );
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader,setLoader] = useState(false);
  const [alertOpen,setAlert] = useState({
    isOpen:false,
    type:AlertStatus.SUCCESS,
    msg:''
  });

  
  const logout = useCallback(() => {
    const token =  localStorage.getItem("token");
    axios
    .get(`${__API_URL__}/api/practitioners/logout/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      localStorage.removeItem("myKey");
      localStorage.removeItem("patientTableConfigurations");
      localStorage.clear()
      
    })
    .catch((err) => {
      console.log(err);
    });
    setToken(null);
    setIsLogin(false);
    setUser(null);
    setLoading(false);
    localStorage.removeItem("token");
    localStorage.removeItem("myKey");
    localStorage.clear()
    navigate("/login");
  }, []);

  const fetchUser = useCallback(
    (options) => {
      return new Promise((resolve, reject) => {
        const userURL = `${__API_URL__}/api/practitioners/personal-info/`;
        const userRequest = axios.get(userURL, options);

        const request = [userRequest];

        axios
          .all(request)
          .then(
            axios.spread((...responses) => {
              setUser(responses[0].data);
              // setIsLogin(false);
              setLoading(false);
              resolve(123);
            })
          )
          .catch((err) => {
            console.log(err);
            if (axios.isAxiosError(err)) {
              logout();
              resolve(123);
            }
            reject(err);
          });
      });
    },
    [logout]
  );

  const login = useCallback(
    (ld) => {
      setLoading(true);
      return (
        axios.post(`${__API_URL__}/api/practitioners/login/`, ld)
          .then(async (res) => {
            setToken(res.data.token);
            localStorage.setItem("token", res.data.token);
            let patientTableConfigurations = {
                filters: [],
                sort: [],
                columns: [],
                orderOfColumns: [],
                noOfRows: 10
            }
            // localStorage.setItem('patientTableConfigurations', JSON.stringify(patientTableConfigurations))
            // localStorage.setItem("clinicId", res.data.practitioner_data.practitioner_clinic[0].id)
            // localStorage.setItem("clinicName", res.data.practitioner_data.practitioner_clinic[0].name)

            localStorage.setItem("user_data", JSON.stringify({
              "id": res.data.id,
              "full_name": res.data.full_name,
              "role": res.data.role,
              "email": res.data.email,
              "owner_id": res.data.owner_id
            }))

            const options = {
              headers: { Authorization: `Token ${res.data.token}` },
            };

            await fetchUser(options);
            return true;
          })
          .catch((err) => {
            setLoading(false);
            return false;
          })
      );
    },
    [fetchUser]
  );

  const forgot_password = (email) =>{
    axios
    .post(`${__API_URL__}/api/auth/staff/forgot-password/`, {email:email})
    .then((res) => {
      toast.success("We have sent you an email with password reset link.")
    }).catch((err) => {
      toast.error(err.response.data.message)
    });
  }

  const reset_password = (newPass) =>{
    axios
    .post(`${__API_URL__}/api/auth/staff/change_password_from_email/`, newPass)
    .then((res) => {
      navigate("/login");
    }).catch((err) => {
      console.log(err)
    });
  }

  const setIsLoginAction = (state) =>{
    setIsLogin(state);
  }

  useLayoutEffect(() => {
    if (
      localStorage["token"] != undefined &&
      user == null
    ) {
      const options = {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      };
      const getUser = async () => {
        setLoading(true);
        await fetchUser(options);
        setLoading(false);
      };
      getUser();
    }
  }, [fetchUser, user]);

  return (
    <UserContext.Provider
      value={{
        isLogin,
        actions: {
          login,
          forgot_password,
          reset_password,
          logout,
          setIsLoginAction,
        },
        loading,
        loader,
        setLoading:setLoader,
        customalert:{
          Input:alertOpen,
          setAlert:setAlert
        },
        state: {
          user,
          token,
        },
      }}
    >
      {isLogin ? (
        token == null || user == null ? (
          <Loader />
        ) : (
          
          children
        )
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);
export default useUser;
