import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useUser from "./hooks/user";
import Dashboard from "./Pages/Dashboard";
import LoginPage from "./Pages/Login";
import Demo from "./Pages/Calendar";
import PatientArchive from "./Pages/Patient/PatientArchive";
import PatientCase from "./Pages/Patient/PatientCase";
import PatientInfo from "./Pages/Patient/PatientInfo";
import PatientAppointments from "./Pages/Patient/PatientAppointments";
import UpdateAppointment from "./Pages/Patient/UpdateAppointment";
import CreateAppointment from "./Pages/Patient/CreateAppointment";
import PatientMessages from "./Pages/Patient/PatientMessages";
import PatientPhotos from "./Pages/Patient/PatientPhotos";
import GalleryView from "./Pages/Patient/GalleryView";
import PatientExternalMedia from "./Pages/Patient/PatientExternalMedia";
import ExternalMediaGalleryView from "./Pages/Patient/ExternalMediaGalleryView";
import ExternalMediaPatientCase from "./Pages/Patient/ExternalMediaPatientCase";
import Clinic from "./Pages/Clinic/Clinic";
import YourInventory from "./Pages/Inventory/YourInventory";
import NewDashboard from "./Pages/NewDashboard";
import PhotoReport from "./Pages/activity/photo-report";
import PatientActivity from "./Pages/Patient/PatientActivity";
import StaffList from "./Pages/Staff/StaffList";
import AddOwner from "./Pages/Staff/AddOwner";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/forgotPassword";
import AddStaff from "./Pages/Staff/AddStaff";
import AddInvestigator from "./Pages/Staff/AddInvestigator"
import AddCroAdmin from "./Pages/Staff/AddCroAdmin"
import AddInvCoordinator from "./Pages/Staff/AddInvCooridinator";
import AddCroCoordinator from "./Pages/Staff/AddCroCoordinator";
import Reward from "./Pages/Patient/Rewards";
import CreateReward from "./Pages/Patient/RewardCreate";
import AdHocReport from "./Pages/activity/AdHocReport";
import FullForm from "./Pages/Ecrf/FullForm";
import Trials_List from "./Pages/Trials/Trials_List";
import TrialDetails from "./Pages/Trials/Trials_Details";
import PoemList from "./Pages/Poem/PoemList";
import PoemDetail from "./Pages/Poem/PoemDetail";
import EConsent from "./Pages/Poem/eConsent";
import EconsentDetails from "./Pages/Poem/EconsentDetails";
import ChangeLogs from "./Pages/Poem/ecrfChangeLogs";
import ProofVideos from "./Pages/Poem/proofVideos";
import VideoDetail from "./Pages/Poem/VideoDetail";
import NotificationList from "./Pages/Notifications/notification_list"
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import UploadDocuments from "../src/components/AdditionalDocuments/UploadDocuments"
import SurveyPoem from "./components/Survey/SurveyPoem";
import Summary_Trial from "./Pages/Trials/Summary_Trial";
import SurveyCreatorWidget from "./Pages/FormCreater/SurveyCreatorReact"
import SurveyListWidget from "./Pages/FormCreater/SurveyList"
import FormCreater from "./Pages/FormCreater/FormCreater"
import NewEcrf from "./Pages/FormCreater/NewEcrf";
import ManuallyMarkVisit from "./components/TrialAccordions/ManuallyMarkVisit";
import { setLicenseKey } from "survey-core";
import ExternalPhotos from "./Pages/Patient/ExternalPhotos";
import ExternalPhotoSessionView from "./Pages/Patient/ExternalPhotoSessionView";
import QueryTable from "../src/components/Query/QueryTable";
import QueryChangeLogs from "./components/Query/QueryChangeLog";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
setLicenseKey(process.env.REACT_APP_SURVEY_CREATOR_LICENSE_KEY);
setLicenseKey(process.env.REACT_APP_SURVEY_PDF_LICENSE_KEY);

const App = () => {
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  return (
    <Routes>
      {user.isLogin ? (
        <>
          {(user_data.role === "Reviewer" || user_data.role === "CRO Admin" || user_data.role ==="Cro_coordinator" || user_data.role ==="Data Manager") && (
            <Route exact path="/" element={<Trials_List />} />
          )}
          {(user_data.role === 'Inv_coordinator' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator'  || user_data.role ==='Super Admin' || user_data.role ==='Clinic Admin' || user_data.role ==='Clinic Owner' || user_data.role ==='Practitioner') && <>
          <Route exact path="/" element={<NewDashboard />} />
          </>
          }
          <Route exact path="/calendar" element={<Demo />} />
          {(user_data.role === 'Super Admin' || user_data.role === 'CRO Admin' || user_data.role ==="Cro_coordinator" || user_data.role ==="Data Manager" || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' ? <>
          <Route exact path="/survey-poem" element={<SurveyPoem />} />
          <Route exact path="/form-creater" element={<FormCreater />} />
          <Route exact path="/form-creater-v2-list" element={<SurveyListWidget />} />
          <Route exact path="/form-creater-v2" element={<SurveyCreatorWidget />} />
          <Route exact path="/patient/:_id_/ecrf-page/" element={<NewEcrf />} />
          
          {/* <Route exact path="/form-creater" element={<FormCreater />} /> */}
          </>
          :"")}
          
          <Route exact path="/patient/" element={<NewDashboard />} />
          <Route exact path="/patient/archive" element={<PatientArchive />} />
          <Route exact path="/patient/photo-report" element={<PhotoReport />} />
          <Route exact path="/patient/adhoc-report" element={<AdHocReport />} />
          <Route exact path="/patient/:_id_/info" element={<PatientInfo />} />
          <Route exact path="/patient/:_id_/appointment" element={<PatientAppointments />} />
          <Route exact path="/patient/:_id_/appointment/:UpdateId" element={<UpdateAppointment />} />
          <Route exact path="/patient/:_id_/appointment/create" element={<CreateAppointment />} />
          <Route exact path="/patient/:_id_/activity" element={<PatientActivity />} />
          <Route exact path="/patient/:_id_/messages" element={<PatientMessages />} />
          <Route exact path="/patient/:_id_/photos" element={<PatientPhotos />} />
          <Route exact path="/patient/:_id_/gallery" element={<GalleryView />} />
          <Route exact path="/patient/:_id_/photos/:caseId" element={<PatientCase />} />
          {(user_data.role ==="Inv_coordinator" || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'CRO Admin' || user_data.role === 'Cro_coordinator' || user_data.role ==="Data Manager") && <>
          <Route exact path="/patient/:_id_/sessions_external_media" element={<PatientExternalMedia />} />
          <Route exact path="/patient/:_id_/sessions_external_media_gallery" element={<ExternalMediaGalleryView />} />
          <Route exact path="/patient/:_id_/sessions_external_media/:caseId" element={<ExternalMediaPatientCase />} />
          </>}
          <Route exact path="/patient/:_id_/ecrf/" element={<FullForm />} />
          <Route exact path="/patient/:_id_/poem/" element={<PoemList />} />
          <Route exact path="/patient/:_id_/poem/:caseId" element={<PoemDetail />} />
          {
            (user_data.role === "Super Admin" || user_data.role === "CRO Admin" || user_data.role === "Cro_coordinator" || user_data.role ==="Investigator" || user_data.role ==="Inv_coordinator" || user_data.role ==="Sub_investigator" || user_data.role ==="Data Manager" ) &&
            <Route exact path="/patient/:_id_/query/" element={<QueryTable />} />
          }
          <Route exact path="/patient/:_id_/video/:caseId" element={<VideoDetail />} />
          <Route exact path="/patient/:_id_/e_consent/:caseId" element={<EconsentDetails />} />
          <Route exact path="/patient/:_id_/ecrf_change_logs/" element={<ChangeLogs />} />
          <Route exact path="/patient/:_id_/query_change_logs/" element={<QueryChangeLogs />} />
          <Route exact path="/patient/:_id_/videos/" element={<ProofVideos />} />
          <Route exact path="/patient/:_id_/e_consent/" element={<EConsent />} />
          <Route exact path="/patient/:_id_/notification_list/" element={<NotificationList />} />
          <Route exact path="/clinic_trials/" element={<Trials_List />} />
          <Route exact path="/patient/:_id_/documents" element={<UploadDocuments />} />
          <Route exact path="/patient/:_id_/visit_status" element={<ManuallyMarkVisit />} />
          <Route exact path="/clinic_trials/:_id_/" element={<TrialDetails />} />
          <Route exact path="/patient/:_id_/external_photo/" element={<ExternalPhotos />} />
          <Route exact path="/patient/:_id_/external/:caseId"  element={<ExternalPhotoSessionView />} />
          
          {(user_data.role === "Super Admin" || user_data.role === "CRO Admin" || user_data.role ==="Cro_coordinator" || user_data.role ==="Data Manager" || user_data.role === "Inv_coordinator"  || user_data.role === "Investigator" || user_data.role === 'Sub_investigator' ) && (
          <Route exact path="/summary_trial/:_id_/" element={<Summary_Trial />} />
          )}

          {(user_data.role != "Practitioner")&& (
            <Route exact path="/staff/" element={<StaffList />} />
          )}
          {(user_data.role == "Super Admin" || user_data.role == "Clinic Owner" || (user_data.role == "Investigator" || user_data.role === 'Sub_investigator' || user_data.role == "Inv_coordinator" )) && (
            <Route exact path="/clinic" element={<Clinic />} />
            )}
          <Route exact path="/inventory/your-inventory" element={<YourInventory />}
          />
          <Route exact path="/patient/:_id_/reward" element={<Reward />} />
          <Route exact path="/patient/:_id_/reward/create" element={<CreateReward />}
          
          />
        </>
      ) : (
        <>
          <Route path="*" element={< LoginPage />} />
        </>
      )}
      {!user.isLogin ? (
        <>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/forgot_password" element={<ForgotPassword />} />
          <Route path="auth/password/reset/:uid/:token" element={<ResetPassword />} />
        </>
      ) : (
        <Route path="/login" element={<Navigate to="/" />} />
      )}
      </Routes>
  );
};

export default App;
