import * as React from 'react'
import { Link } from "react-router-dom";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from '../../hooks/user';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import SourceTwoToneIcon from '@mui/icons-material/SourceTwoTone';
// import logo from "../../assets/img/appiellLogo.svg"
//new logo added.
import logo from "../../assets/img/smartTrail.svg"

// -----------------------
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

const NavBar = React.memo(({active, setAddStaff}) => {
  const user = useUser();


//// ---------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user_data = JSON.parse(localStorage.getItem('user_data'))
    const handleStaffClick = () => {
  if (setAddStaff){
      setAddStaff(false)
    };
  }
  const getUserAvatarInitial = (role) => {
    switch (role) {
      case 'Super Admin':
        return 'S';
      case 'CRO Admin':
      case 'Cro_coordinator':
        return 'K';
      case 'Data Manager':
          return 'DM';
      case 'Investigator':
        return 'I';
        case 'Inv_coordinator':
        return 'C';
      case 'Reviewer':
        return 'R';
      case 'Sub_investigator':
        return 'SI';
      default:
        return user_data.email?.substring(0, 1).toUpperCase();
    }
  };

  return (
    <div className='navHeader'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-2'>
                  <Link to="/">
                  <img src={logo} alt='' />
                  </Link>
                </div>
                <div className="col-md-10">
                    <ul>
                      {user_data.role !== 'Reviewer' && user_data.role !== 'CRO Admin' && user_data.role !=='Cro_coordinator' && user_data.role !=='Data Manager' && <>
                        <li className={active === "calender" && "active"}>
                        <Link to={(user_data.role !== "Investigator") && (user_data.role !== "Inv_coordinator") && (user_data.role !== "Sub_investigator")? "/" : "/calendar/"}>
                          <img src="https://i.ibb.co/FJfGjYM/i2.png" alt='' />
                          Calendar
                          </Link>
                        </li>
                      </>}
                      <li className={active === "patient" && "active"}>
                      <Link to="/patient/">
                        <img src="https://i.ibb.co/nnSMkp8/i1.png" alt='' />
                          {user_data.role === 'CRO Admin'  || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || user_data.role ==='Cro_coordinator' || user_data.role ==='Data Manager' ? "Subjects" : "Patients"}
                        </Link>
                        </li>
                        <li className={active === "trials" && "active"}>
                        <Link to="/clinic_trials/">
                          <img src="https://i.ibb.co/FJfGjYM/i2.png" alt='' />
                          Trials
                          </Link>
                        </li>
                      {user_data.role !== 'Reviewer' && <>
                        {user_data.role === 'Super Admin' || user_data.role === 'Clinic Owner' || (user_data.role === 'Investigator' || user_data.role === 'Inv_coordinator' || user_data.role === 'Sub_investigator')? <> 
                            <li className={active === "clinic" && "active"}>
                            <Link to="/clinic/">
                              <img src="https://i.ibb.co/FJfGjYM/i2.png" alt='' />
                              Clinic
                              </Link>
                            </li>
                        </>: ''}
                        { user_data.role !== 'CRO Admin' && user_data.role !== 'Cro_coordinator' && user_data.role !=='Data Manager' &&
                        <>
                          <li className={active === "inventory" && "active"}>
                          <Link to="/inventory/your-inventory/">
                          <img src='https://i.ibb.co/Xx991S4/i4.png' alt='' />
                          Inventory
                          </Link>
                        </li>
                        </>
                        }
                        {user_data.role === 'Reviewer' ? <>               
                          <li className={active === "staff" && "active"} onClick={handleStaffClick}>
                          <Link to="/staff/">
                            <img src="https://i.ibb.co/pd2Lwb1/i3.png" alt='' />
                            Staff
                            </Link>
                          </li>
                        </>
                        :
                        (user_data.role !== 'Practitioner' && user_data.role !== 'Cro_coordinator' && user_data.role !=='Data Manager') && (user_data.role !== 'Inv_coordinator' && user_data.role !== 'Sub_investigator') ?
                        <> 
                        <li className={active === "staff" && "active"} onClick={handleStaffClick}>
                        <Link to="/staff/">
                          <img src="https://i.ibb.co/pd2Lwb1/i3.png" alt='' />
                          Staff
                          </Link>
                        </li>
                      </>
                      :
                      ""
                        }
                        {/* {user_data.role != 'Practitioner' && <> 
                          <li className={active == "staff" && "active"}>
                          <Link to="/staff/">
                            <img src="https://i.ibb.co/pd2Lwb1/i3.png" alt='' />
                            Staff
                            </Link>
                          </li>
                        </> } */}
                        { user_data.role !== 'CRO Admin' && user_data.role !=='Cro_coordinator' && user_data.role !=='Data Manager' &&
                        <>
                         <li className={active === "photo-report" && "active"}>
                        <Link to="/patient/photo-report">
                        <img src="https://i.ibb.co/tDj3Qr4/i6.png" alt='' />
                          Reports
                          </Link>
                        </li>
                        </>}
                        <li className={active === "adhoc-report" && "active"}>
                        <Link to="/patient/adhoc-report">
                        <div className="icon-with-text">
                          <AssessmentTwoToneIcon />
                          <span className="text">Report Generation</span>
                        </div>
                          </Link>
                        </li>
                        </>
                        }
                        {(user_data.role === 'Super Admin' || user_data.role === 'CRO Admin' ? <>
                        <li className={active === "survey" && "active"}>
                        <Link to="/survey-poem">
                        <div className="icon-with-text">
                          <QuestionAnswerTwoToneIcon />
                          <span className="text">Survey</span>
                        </div>
                        </Link>
                        </li>
                      </>:"")}
                        {(user_data.role === 'Super Admin' || user_data.role === 'CRO Admin' || user_data.role ==="Cro_coordinator" || user_data.role ==='Data Manager' ? <>
                        <li className={active === "form_creater" && "active"}>
                        <Link to="/form-creater-v2-list">
                        <div className="icon-with-text">
                        <SourceTwoToneIcon />
                          <span className="text">Source Doc Creator</span>
                        </div>
                        </Link>
                        </li>
                      </>:"")}
                      <li>
                      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="Account settings">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                          >
                            <Avatar sx={{ width: 32, height: 32 }}>{getUserAvatarInitial(user_data.role)}</Avatar>
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <MenuItem>
                            <b>{user_data.email}</b>
                        </MenuItem>
                        <MenuItem>
                          <small>[{user_data.role}]</small>
                        </MenuItem>
                        <Divider />
                        {/* <MenuItem>
                          <Avatar /> Profile
                        </MenuItem>
                        <MenuItem>
                          <Avatar /> My account
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                          <ListItemIcon>
                            <PersonAdd fontSize="small" />
                          </ListItemIcon>
                          Add another account
                        </MenuItem>
                        <MenuItem>
                          <ListItemIcon>
                            <Settings fontSize="small" />
                          </ListItemIcon>
                          Settings
                        </MenuItem> */}
                        <MenuItem onClick={() => user.actions.logout()}>
                          <ListItemIcon>
                            <Logout fontSize="small" />
                          </ListItemIcon>
                          Logout
                        </MenuItem>
                      </Menu>
                      </li>
                    </ul>
                </div>
            </div>

        </div>

    </div>
  )
})

export default NavBar