import React from 'react'
import BasicTabs from '../Trials/Visit_tabs'
import MainAccordion from '../Accordion/accordion'

const VisitTabAccordion = ({numberOfVisits, setNumberOfVisits, setMessage, selectedVisit, setSelectedVisit, setTypeOfMessage, trialId, setModalVisitChangeShow, hasChanged, setHasChanged, AllVisitsData, handleSelectingVisit, zeroVisit, eventList,value, setValue, allVisits, surveyFormBool, setSurveyFormBool, consentFormBool, setConsentFormBool, numberOfVisitsArray, setNumberOfVisitsArray, isEvent, selectedSessionId, setSelectedSessionId, setAddNewSurvey, setLoader, loader, allSurveys, trialDetails}) => {
  return (
    <MainAccordion
    title="Trial Visit & Source Document"
    data={
        <BasicTabs
        value={value}
        setValue={setValue}
        allVisits={allVisits}
        isEvent={isEvent}
        surveyFormBool={surveyFormBool}
        numberOfVisitsArray={numberOfVisitsArray}
        setNumberOfVisitsArray={setNumberOfVisitsArray}
        setSurveyFormBool={setSurveyFormBool}
        consentFormBool={consentFormBool}
        setConsentFormBool={setConsentFormBool}
        numberOfVisits={numberOfVisits}
        zeroVisit={zeroVisit}
        handleSelectingVisit={handleSelectingVisit}
        eventList={eventList}
        selectedVisit={selectedVisit}
        setSelectedVisit={setSelectedVisit}
        AllVisitsData={AllVisitsData}
        setNumberOfVisits={setNumberOfVisits}
        setMessage={setMessage}
        setTypeOfMessage={setTypeOfMessage}
        trialId={trialId}
        setModalVisitChangeShow={setModalVisitChangeShow}
        hasChanged={hasChanged}
        setHasChanged={setHasChanged}
        selectedSessionId={selectedSessionId}
        setSelectedSessionId={setSelectedSessionId}
        setAddNewSurvey={setAddNewSurvey}
        setLoader={setLoader}
        loader={loader}
        allSurveys={allSurveys}
        trialDetails={trialDetails}
        />}
    />
  )
}
export default VisitTabAccordion