import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router";
import Breadcrumbs from "../../components/breadcrumbs";
import ImageCompare from "./ImageCampare";
import MessageBox from "../../components/Alert/MessageBox";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import useUser from '../../hooks/user';
import { type } from "@testing-library/user-event/dist/type";
import Avatar from "../../assets/img/avatar.png";
import RetakePhotoPopUp from "../../components/PopUp/RetakePhoto";
import ImageCompareSession from "../Patient/ImageCompareSession"

const GalleryView = () => {
  const { _id_ } = useParams();
  const user = useUser();
  const trial_name_filter = localStorage.getItem("trial_name_filter");
  const trial_status_filter = localStorage.getItem("trial_status_filter");
  const trial_name = localStorage.getItem("trial_name");
  const pass_number = localStorage.getItem("pass_number");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("token");
  const role = user_data.role
  const [modalRetake, setModalRetake] = useState(false);
  const [photoId, setPhotoId] = useState();
  const [scoreUser, setScoreUser] = useState();
  const [infoData, setInfoData] = useState({})
  const [PatientData, setPatientData] = useState("");
  const [Loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState({
      photo_types: '',
      area: '',
      trial_area: '',
      trails: '',
  })


  const handleRetakeModal= (id) =>{
    setModalRetake(true)
    setPhotoId(id)
  }

  const GetGalleryPhotos = () => {
    axios
      .get(`${__API_URL__}/api/patient/patient/photo_session_gallery/?PatientId=${_id_}&photo_type=${filterData?.photo_types}&photo_area=${filterData?.area}&selected_user=${filterData?.selected_user}&trial_area=${filterData?.trial_area}&trialCroFilter=${filterData?.trial_area}&trialName=${trial_name_filter}&trialStatus=${trial_status_filter}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      });
  };

  const[scoreData, setScoreData] = useState({})
  const [savingScores, setSavingScores] = useState(false)
  const [photoForms, setPhotoForms] = useState({})
  const [trailsList, setTrialsList] = useState([]);
  const handleFormChange = (e, photoId) => {
      const {name, value} = e.target;
      const prevFormObject = photoForms[photoId]
      const updatedFormObject = {
         ...prevFormObject, [name]: value
      }
      setPhotoForms((prevState) => {
          return {
              ... prevState, [photoId]: updatedFormObject
          }
      })
  }


  const handleSubmission = (photoId) => {
    const postData = photoForms[photoId]
    const updatedData = {
        updated_score: '-1',
        glabellar_frown_pattern_choices: '0',
        evaluation_time: 0,
        extra_comments: '',
        photo_id: photoId,
        pass_number: pass_number ? pass_number : '',
        ...postData
    }

    if (updatedData['updated_score'] == -1 || updatedData['updated_score'] === '-' || updatedData['evaluation_time'] === 0) {
        setToastrMessage("Fill the compulsory fields!")
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        return
      }

    axios
      .post(
        `${__API_URL__}/api/patient/patient/evaluation_update/`,
        updatedData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setToastrMessage("Evaluation Updated!")
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        window.location.reload();
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 400') {
          setToastrMessage("Please set the pass number!")
          setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
          setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        }
      });
  }



  const handleScore = (photoId) => {
    setSavingScores(true)
    axios
      .patch(`${__API_URL__}/api/ecrf/update_score/`, scoreData[photoId], {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setToastrMessage("Score Updated!")
        setScoreData((prev) => {return {...prev, [photoId]: res.data.data}})
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
        setSavingScores(false)
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 400') {
          setToastrMessage("Please set the Score!")
          setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
          setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
          setSavingScores(false)
        }
      });
    };

  const handleChange = (e)=>{
    const {name, value} = e.target
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const [photoTypes, setPhotoTypes] = useState([])
  const [areas, setAreas] = useState([])
  const [trialAreas, setTrialAreas] = useState([])
  const photoTypeMapper = {
      0: 'Repose full frontal',
      1: 'Repose full lateral left',
      2: 'Repose full lateral right',
      3: 'Repose half lateral left',
      4: 'Repose half lateral right',
      5: 'Active full frontal surprised (raised eyebrows)',
      6: 'Active full frontal frowning',
      7: 'Active full frontal saying EEEEE',
      8: 'Active full frontal max smile',
      9: 'Active full lateral left max smile',
      10: 'Active full lateral Right max smile',
      11: 'Active half lateral left max smile',
      12: 'Active full lateral right max smile',
      13: 'Lesion 1',
      14: 'Lesion 2',
      15: 'Lesion 3',
      16: 'ALL 10 TOES',
      17: 'BIG TOE LEFT FOOT',
      18: 'BIG TOE RIGHT FOOT',
  }

  const populateFiltersApi = (q) => {
      axios
      .get(`${__API_URL__}/api/photos/photos_filter_dropdowns/?type=${q}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        switch (q) {
          case 'photo_types':
            setPhotoTypes(res.data)
            break;
          case 'assessment_areas':
            setAreas(res.data)
            break;
          case 'trial_areas':
            setTrialAreas(res.data)
            break;
        }
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  }
  const GetScoredUser = () => {
      axios
      .get(`${__API_URL__}/api/ecrf/get_score_user/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setScoreUser(res.data)
      }).catch((err) => {
        if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
      });
  }
  const SinglePatient = () => {
    axios
    .get(`${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`, {
        headers: {
        Authorization: `Token ${token}`,
        },
        
    })
    .then((res) => {
        setInfoData(res.data)
    });
}

  const UpdateInstance = (e, ins) => {
    const score = document.getElementsByClassName(ins)[0];
    const time = document.getElementsByClassName(ins)[1];
    const classList = document.getElementsByClassName("glab" + ins).length > 0;
    const token = localStorage.getItem("token");
    var glab;
    if (classList) {
      glab = document.getElementsByClassName("glab" + ins)[0];
      glab = glab.value;
    } else {
      glab = "-";
    }
    const updateData = {
      score: score.value,
      evaluation_time: time.value,
      glabellarFrownPattern: glab,
      photo_id: ins,
    };
    axios
      .post(
        `${__API_URL__}/api/patient/patient/evaluation_update/`,
        updateData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setTimeout(() => {document.getElementById('cusMessages').style.right = '2rem';}, 2000);
        setTimeout(() => {document.getElementById('cusMessages').style.right = '-22rem';}, 6000);
      })
      .catch((err) => {
        console.log(err);
      });

    e.preventDefault();
  };

  const getSrc = (src, type) => {
    if (type === "compare") {
      document.getElementsByClassName("fixImage")[0].src = src;
      document.getElementsByClassName("imageCompare")[0].style.display = "block";
    } else {
      document.getElementsByClassName("fixImageZoom")[0].src = src;
      document.getElementsByClassName("zoomImage")[0].style.display = "block";
    }
  };
  
  useEffect(()=>{
    GetGalleryPhotos()
  },[filterData])

  useEffect(() => {
    populateFiltersApi('photo_types');
    populateFiltersApi('assessment_areas');
    populateFiltersApi('trial_areas');
    getTrialsList()
    GetScoredUser()
    SinglePatient()
  }, [])

  useEffect(() => {
    !Loader && populateExistingPhotoScores();
  }, [Loader])

  const [blocks, setBlocks] = useState({})

  useEffect(() => {
    let photosObj = {}
    let blocksObj = {}
    PatientData?.data?.map((sessionObject) => {
        let blocksList = []
        sessionObject.blocks?.map((b) => {
           if (b.name === "Target Lesion Erythema Assessment") blocksList.push("Target Lesion Erythema Assessment")
           if (b.name === "Target Lesion Severity Assessment") blocksList.push("Target Lesion Severity Assessment")
           if (b.name === "Target Lesion Improvement Assessment") blocksList.push("Target Lesion Improvement Assessment")
        })
        blocksObj[sessionObject.id] = blocksList
        sessionObject.photo.map((photo) => {
          photosObj[photo.id] = photo
        })
    })
    setScoreData(photosObj)
    setBlocks(blocksObj)
}, [PatientData])

  const populateExistingPhotoScores = () => {
    PatientData?.data?.map((row) => {
        row.photo.map((photo) => {

          setPhotoForms((prev) => {
              const updatedObj = {
                updated_score: pass_number === '1' ? photo.updated_score : pass_number === '2' ? photo.updated_score_v1 : pass_number === '3' ? photo.updated_score_v2 : '-1',
                glabellar_frown_pattern_choices: pass_number === '1' ? photo.glabellar_frown_pattern_choices : pass_number === '2' ? photo.glabellar_frown_pattern_choices_v1 : pass_number === '3' ? photo.glabellar_frown_pattern_choices_V2 : '0',
                evaluation_time: pass_number === '1' ? photo.evaluation_time : pass_number === '2' ? photo.evaluation_time_v1 : pass_number === '3' ? photo.evaluation_time_v2 : 0,
                extra_comments: pass_number === '1' ? photo.extra_comments_v1 : pass_number === '2' ? photo.extra_comments_v2 : pass_number === '3' ? photo.extra_comments_v3 : '',
              }
              return {
                ... prev, 
                [photo.id]: {
                    ...updatedObj, 
                    disabled: updatedObj.updated_score == -1 ? false : updatedObj.updated_score === '-' ? false : updatedObj.evaluation_time === 0 ? false : true
                }
              }
          })



        })
    })

  }

  const handleRetakePhoto = () => {
      axios
      .get(`${__API_URL__}/api/photos/retake_photo/${photoId}/`, {
          headers: {
          Authorization: `Token ${token}`,
          },
      })
      .then((res) => {
          setToastrMessage("Photo Deleted");
          setTypeOfMessage("success");
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
          setTimeout(() => {
          window.location.reload();
          }, 1000);
      }).catch((err) => {
          if(err == 'Error: Request failed with status code 401'){
          user.actions.logout()
          }
          setToastrMessage("Something Went Wrong");
          setTypeOfMessage("success");
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "2rem";
          }, 100);
          setTimeout(() => {
          document.getElementById("cusMessages").style.right = "-99rem";
          }, 3000);
      });
  }

  const [toastrMessage, setToastrMessage] = useState('Evaluation Updated!')
  const [typeOfMessage, setTypeOfMessage] = useState('')

  
  const getTrialsList = () => {
    axios
      .get(`${__API_URL__}/api/photos/get_patient_trials/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTrialsList(res.data);
      }).catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      });
  }
  return (
    <>
    <MessageBox msg={toastrMessage} type={typeOfMessage}/>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs 
        B="Photos - Gallery View" 
        F={infoData?.first_name}
        L={infoData?.last_name}
        E={infoData?.email}
        P={infoData?.phone}
        participant_id={infoData?.participant_id}
        age={infoData?.age}
        birth_date={infoData?.birth_date}
      />
        <div className="patient_table patient_photos">
          <PatientMenu active="photos" data={infoData}/>
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <Link to={"/patient/" + _id_ + "/photos"}>
                  <button type="button" class="btn">
                    Session View
                  </button>
                </Link>
                <Link to={"/patient/" + _id_ + "/gallery"}>
                  <button type="button" class="btn btn-primary">
                    Gallery View
                  </button>
                </Link>
              </div>
            </div>
            {(role !== 'CRO Admin' && role !== 'Investigator' && role !== 'Cro_coordinator' && role !== 'Data Manager' && role !== 'Inv_coordinator') && <>
              <div className="row">
                <div className="col-md-3">
                  <label>Types</label>
                  <select className="form-control photoFilter" onChange={(e) => handleChange(e)} name="photo_types">
                    <option value="">All Types</option>
                    {photoTypes.map((type) => {
                        return <>
                            <option value={type.id}>
                                {photoTypeMapper[type.name]}
                            </option>
                        </>
                    })}
                  </select>
                </div>
                <div className="col-md-3">
                  <label>Areas</label>
                  <select className="form-control photoFilter" onChange={(e) => handleChange(e)} id="trial_area" name="area">
                    <option value="">All Areas</option>
                    {
                      areas.map((area) => {
                        return <>
                            <option value={area.id}>{area.name}</option>
                        </>
                      })
                    }
                  </select>
                </div>
                {
                  trialAreas.length !== 0 && <>
                  <div className="col-md-3">
                    <label>Areas</label>
                    <select className="form-control photoFilter" onChange={(e) => handleChange(e)} name="trial_area">
                      <option value="">All Trial Areas</option>
                      {
                        trialAreas.map((area) => {
                          return <>
                              <option value={area.id}>{area.name}</option>
                          </>
                        })
                      }
                    </select>
                  </div>
                  </>
                }
              </div>
              </>
              }
              <div className="row">
              {
                (role === 'CRO Admin' || role === 'Investigator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === 'Inv_coordinator' || role === 'Super Admin' || role ==='Reviewer') &&
                    <>
                  
                      <div className="col-md-3">
                          <label>Trials</label>
                          <select className="form-control photoFilter" onChange={(e) => handleChange(e)} id="selected_trial" name="trial_area">
                            <option value="">All Trial Areas</option>
                            <option value={0}>Appiell_Wrinkles</option>
                            {
                              trailsList.map((area) => {
                                return <>
                                    <option value={area.id}>{area.name}</option>
                                </>
                              })
                            }
                          </select>
                        </div>
                  </>
              }

                      {
                        (role === 'CRO Admin' || role === 'Super Admin') && <>
                          <div className="col-md-3">
                            <label>Score of</label>
                            <select className="form-control" onChange={(e) => handleChange(e)} id="selected_user" name="selected_user">

                            <option value=''>Select role</option>
                            {scoreUser?.map((user) => {
                              return <>
                              <option value={user?.id}>{user?.email}</option>

                              </>
                            })

                            }
                            </select>
                          </div>
                          </>
                        }
              </div>
          </div>
          {Loader ? (
            <div className="loader">
              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
            </div>
          ) : (
            <>
              {PatientData?.data?.map((row, index) => (
                <div className="row caseSection" key={row.id}>
                  {row.photo.length !== 0 && <>
                  <p>
                    <span style={{ "padding-right": "40px" }}>
                      {new Date(row.created_at + "Z").toDateString()},&nbsp;
                      {new Date(row.created_at + "Z").toLocaleTimeString()}&nbsp;&nbsp;
                    {row.is_trial && 
                    <>
                      Visit Number:
                        <span style={{ marginLeft: '5px' }}>
                          {(row.visit_name && row.visit_name !== "") ?
                            row.visit_name :   
                            row.visit_number === 0
                            ? "Baseline Visit"
                            : row.visit_number === -3
                            ? "Screening Visit"
                            : row.visit_number === -4
                            ? "Other Ecrfs"
                            : row.visit_number === -1
                            ? "Adverse Event"
                            : row.visit_number === -2
                            ? "Unscheduled Event" 
                            : row.visit_number ?
                            `Visit ${row.visit_number}` : ""}
                        </span>
                  </>
                    }     
                    </span>
                    {
                      (!row.is_trial && (role !== 'Reviewer' && role != 'Investigator')) &&
                      <>
                        <span style={{ "padding-right": "40px" }}>
                          Repose Score : {row.scores.repose_score}
                        </span>
                        <span style={{ "padding-right": "40px" }}>
                          Active Score : {row.scores.active_score}
                        </span>
                      </>
                    }
                    
                  </p>
                    </>}
                  {row.photo.map((photo, i) => (
                    <div className="col-md-3" key={photo.id}>
                      {
                          (role === 'Investigator' || role === 'Inv_coordinator' || role == 'Super Admin') && <>
                            <button style={{ width: "70px", height: "35px", float:"right"}} type="button" className="btn btn-primary btn-sm" onClick={() => getSrc(__API_URL__+ photo.image, "zoom")}>
                              <i>Zoom</i>
                            </button>
                          </>
                        }
                          {photo.image ?
                          <img
                            src={__API_URL__+ photo.image}
                            onClick={(e) => getSrc(e.target.src, "compare")}
                            alt=""
                          /> :
                          <img
                            src={Avatar}
                            alt=""
                          />
                            }
                      {
                        ((role === 'Investigator' || role === 'Inv_coordinator' || role === 'Reviewer') && (row.has_next_visit_started === false)) && <>
                          <button style={{marginTop: "1em"}} type="button" className="btn btn-primary" onClick={() => {handleRetakeModal(photo.id)}}>
                              Re-Take Photo
                          </button>
                        </>
                      }
                      <div className="caseBox">
                        <h6>{photo.photo_type.name}</h6>
                        <label>
                          Captured At -{" "}
                          {new Date(photo.captured_at + "Z").toDateString()}
                          ,&nbsp;
                          {new Date(
                            photo.captured_at + "Z"
                          ).toLocaleTimeString()}
                        </label>
                        {(role === 'Reviewer' || role === 'CRO Admin' || role === 'Investigator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === 'Inv_coordinator' || role === 'Super Admin') && <>
                          {
                            row.is_trial ? <>
                              <div className="row">
                                {
                                  row?.score_block?.map((item) => (item?.name?.includes("Target Lesion Erythema Assessment") && <>
                                    <div className="col-md-12">
                                      <label>TLE Score</label>
                                      <select className="form-control" 
                                      value={scoreData[photo.id]?.tle_score?.score}
                                      onChange={(e) => setScoreData((prev) => {return {...prev, [photo.id]: {...prev[photo.id], tle_score: {...prev[photo.id].tle_score, score: e.target.value , id: prev[photo.id]?.tle_score?.id}}}})}
                                      >
                                        <option value="">Select</option>
                                        {
                                          item?.option?.map((opt) => {
                                          return <>
                                            <option value={opt.title}>{opt.title}</option>
                                          </>
                                          })
                                        }
                                      </select>
                                    </div>
                                  </>
                                ))
                              }
                                {
                                row?.score_block?.map((item) => (item?.name?.includes("Target Lesion Severity Assessment") && <>
                                    <div className="col-md-12">
                                      <label>TLS Score</label>
                                      <select className="form-control"
                                      value={scoreData[photo.id]?.tls_score?.score}
                                      onChange={(e) => setScoreData((prev) => {return {...prev, [photo.id]: {...prev[photo.id], tls_score: {...prev[photo.id].tls_score, score: e.target.value , id: prev[photo.id]?.tls_score?.id}}}})}
                                      >
                                        <option value="">Select</option>
                                        {
                                          item?.option?.map((opt) => {
                                          return <>
                                            <option value={opt.title}>{opt.title}</option>
                                          </>
                                          })
                                        }
                                      </select>
                                    </div>
                                  </>
                                  ))
                                }
                                {
                                  row?.score_block?.map((item) => (item?.name?.includes("Target Lesion Improvement Assessment") &&
                                    <div className="col-md-12">
                                      <label>TLI Score</label>
                                      <select className="form-control"
                                      value={scoreData[photo.id]?.tli_score?.score}
                                      onChange={(e) => setScoreData((prev) => {return {...prev, [photo.id]: {...prev[photo.id], tli_score: {...prev[photo.id].tli_score, score: e.target.value , id: prev[photo.id]?.tli_score?.id}}}})}
                                     
                                      >
                                        <option value="">Select</option>
                                        {
                                          item?.option?.map((opt) => {
                                          return <>
                                            <option value={opt.title}>{opt.title}</option>
                                          </>
                                          })
                                        }
                                      </select>
                                    </div>
                                          ))
                                      }
                                {
                                  (blocks[row.id]?.includes("Target Lesion Erythema Assessment") || blocks[row.id]?.includes("Target Lesion Severity Assessment") || blocks[row.id]?.includes("Target Lesion Improvement Assessment")) && <>
                                    <div className="col-md-12">
                                      {(role !== 'CRO Admin' && role !== 'Cro_coordinator' && role !== 'Data Manager' && !photoForms[photo.id]?.disabled) && <>
                                          <button type="button" className="btn btn-primary" disabled={savingScores} onClick={() => handleScore(photo.id)}>
                                            {
                                              savingScores ? <><i class="fa fa-spinner fa-spin"></i>Saving</> :
                                              "Save"
                                            }
                                          </button>
                                      </>}
                                    </div>
                                  </>
                                }
                              </div>
                            </> : <> 
                            <form
                            >
                              <label>Updated Score</label>
                              <select className={"form-control " + photo.id}  disabled={(role === 'CRO Admin' || role ==='Cro_coordinator' || role ==='Data Manager' || photoForms[photo.id]?.disabled) && 'disabled'}
                                name='updated_score' value={photoForms[photo.id]?.updated_score} onChange={(e) => handleFormChange(e, photo.id)}>
                                <option value='-1'>-</option>
                                <option value='0'>0</option>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                                <option value='3'>3</option>
                              </select>                            
                              {photo.photo_type.name != "Repose full frontal" && (
                                <>
                                  <label>Glabellar Frown Pattern (Optional)</label>
                                  <select
                                    className={"form-control " + "glab" + photo.id}
                                    disabled={(role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || photoForms[photo.id]?.disabled) && 'disabled'}
                                    name='glabellar_frown_pattern_choices' value={photoForms[photo.id]?.glabellar_frown_pattern_choices} onChange={(e) => handleFormChange(e, photo.id)}
                                  >
                                    <option value='0'>-</option>  
                                    <option value='1'>U</option>
                                    <option value='2'>V</option>
                                    <option value='3'>Omega</option>
                                    <option value="4">
                                      Inverted Omega
                                    </option>
                                    <option value="5">
                                      Converging Arrows
                                    </option>
                                  </select>
                                  </>
                              )}
                              <label>Evaluation Time</label>
                              <div className="d-flex">
                                <input
                                  type="number"
                                  min={0}
                                  disabled={(role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager' || photoForms[photo.id]?.disabled) && 'disabled'}
                                  className={"form-control " + photo.id}
                                  name='evaluation_time' value={photoForms[photo.id]?.evaluation_time} onChange={(e) => handleFormChange(e, photo.id)}
                                />
                              </div>
                              {(role !== 'CRO Admin' && !photoForms[photo.id]?.disabled) && <>
                                <button type="button" className="btn btn-primary" onClick={() => handleSubmission(photo.id)}>
                                  Save
                                </button>
                              </>}
                            </form>
                            </> }
                        </>}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <ImageCompare Images={PatientData?.data} />
              <ImageCompareSession Images={PatientData?.photo} />
            </>
          )}
        </div>
      </div>
      {
            modalRetake && <>
               < RetakePhotoPopUp
                  setModalRetake={setModalRetake}
                  handleRetakePhoto={handleRetakePhoto}

               ></RetakePhotoPopUp>
            </>
        }
    </>
  );
};

export default GalleryView;