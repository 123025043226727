import * as React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { toast } from "react-toastify";

const invStatus = ["Not Done", "Done"];
const croStatus = ["InComplete", "Complete"];
const dmStatus = ["UnFrozen", "Frozen"];
const defaultBlockStatuses = {
  invStatus: "Not Done",
  croStatus: "InComplete",
  dmStatus: "UnFrozen",
};

const UpdateStatusPopUp = ({
  showModal,
  setShowModal,
  blocksSelected,
  QueryLogData,
}) => {
  const [blockStatuses, setBlockStatuses] =
    React.useState(defaultBlockStatuses);
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const token = localStorage.getItem("token");

  const blockStatusChange = (event, type) => {
    const {
      target: { value },
    } = event;
    setBlockStatuses((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const validate = () => {
    if (blocksSelected.length === 0) {
      toast.error("Please Select Blocks To Update!");
      return false;
    }
    if (blocksSelected.length === 0) {
      toast.error("Please Select Blocks To Update!");
      return false;
    }

    return true;
  };

  const handleUpdateBlockStatus = () => {
    const validated = validate();

    if (validated) {
      axios
        .post(
          `${__API_URL__}/api/ecrf/update_block_status/`,
          {
            blocksSelected,
            blockStatuses,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          setShowModal(false);
          toast.success("Status Changed Successfully");
          QueryLogData();
        })
        .catch((err) => {
          toast.error(err.response.data.error);
        });
    }

    setShowModal(false);
    setBlockStatuses(defaultBlockStatuses);
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Block Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          {["Investigator", "Super Admin"].includes(user_data.role) && (
            <>
              <InputLabel>Certification Status</InputLabel>
              <Select
                className="form-control"
                value={blockStatuses.invStatus}
                label="Block Status"
                onChange={(e) => blockStatusChange(e, "invStatus")}
                variant="outlined"
              >
                {invStatus.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {["CRO Admin", "Data Manager", "Super Admin"].includes(
            user_data.role
          ) && (
            <>
              <InputLabel>Block Status</InputLabel>
              <Select
                className="form-control"
                value={blockStatuses.croStatus}
                label="Block Status"
                onChange={(e) => blockStatusChange(e, "croStatus")}
                variant="outlined"
              >
                {croStatus.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {["Data Manager", "Super Admin"].includes(user_data.role) && (
            <>
              <InputLabel>Freeze Status</InputLabel>
              <Select
                className="form-control"
                value={blockStatuses.dmStatus}
                label="Block Status"
                onChange={(e) => blockStatusChange(e, "dmStatus")}
                variant="outlined"
              >
                {dmStatus.map((name) => (
                  <MenuItem key={name} value={name}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleUpdateBlockStatus()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateStatusPopUp;
