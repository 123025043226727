import React, { useState } from "react";
import axios from "axios";
import LoginHeader from "../components/loginHeader";
import LoginFooter from "../components/loginFooter";
import { toast, ToastContainer } from "react-toastify";
import { __API_URL__ } from "../SERVER_URL";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import OTPInput from "otp-input-react";
import useUser from "../hooks/user";
import { Link } from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";

const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [error, setError] = React.useState("");
  const [msg, setMsg] = useState("");
  const [otpState, setOtpState] = React.useState("sending_otp");
  const [showPassword, setShowPassword] = React.useState(false);
  const [OTP, setOTP] = React.useState("");
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [cookies, setCookie] = useCookies(["Trust_Browser"]);
  const [securityInfo, setSecurityInfo] = useState();

  const user = useUser();
  const validateEmail = async (e) => {
    e.preventDefault();
    const re =
      /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

    if (email.match(re)) {
      setError("");
      let loginCred = {
        email,
        password: pass,
      };

      try {
        let response = await user.actions.login(loginCred);
        localStorage.setItem("email", email);

        if (response) {
          const storeBrowserCookie = cookies["Trust_Browser"];
          trustBrowserCookie(storeBrowserCookie);
          setOtpState("otp_verification");
        } else {
          toast.error("Invalid Email Address or Password");
        }
      } catch (error) {
        console.log(">>", error);
        setError("An error occurred during login");
      }
    } else {
      toast.error("Please provide a valid email address");
    }
  };

  const sendVerificationEmail = () => {
    const data = {
      email: email,
    };
    setIsDisable(true);
    axios
      .post(`${__API_URL__}/api/auth/staff/send_otp/`, data)
      .then((response) => {
        toast.success(response.data.message);
        setOtpState("password");
        setResendButtonDisabled(true);
        startResendTimer(60);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsDisable(false);
      });
  };

  const saveToLocalStorage = () => {
    localStorage.setItem("otpState", otpState);
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    verifyOTP();
    localStorage.removeItem("otpState");
  };

  const handleResend = (e) => {
    e.preventDefault();
    sendVerificationEmail();
  };

  const verifyOTP = () => {
    const data = {
      email: email,
      token: OTP,
    };
    axios
      .post(`${__API_URL__}/api/auth/staff/verify_otp/`, data)
      .then(() => {
        toast.success("Successfully Verified");
        setOtpState("Remember_Device");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const rememberDevice = async (e) => {
    e.preventDefault();

    setCookie("Trust_Browser", email, {
      maxAge: securityInfo.remember_days * 86400,
    });
    user.actions.setIsLoginAction(true);
    localStorage.removeItem("otpState");
  };
  const AdminSecurity = () => {
    const security = {
      remember_days: securityInfo,
      valid_login_session_days: securityInfo,
    };
    axios
      .get(`${__API_URL__}/api/security/admin_security/`, security)
      .then((res) => {
        setSecurityInfo(res.data);
      });
  };

  React.useEffect(() => {
    AdminSecurity();
  }, []);

  const notRememberDevice = async (e) => {
    e.preventDefault();
    user.actions.setIsLoginAction(true);
    localStorage.removeItem("otpState");
  };

  const trustBrowserCookie = (storeBrowserCookie) => {
    if (storeBrowserCookie) {
      user.actions.setIsLoginAction(true);
      localStorage.removeItem("otpState");
    } else setOtpState("otp_verification");
  };

  React.useEffect(() => {
    const storedOtpState = localStorage.getItem("otpState");
    const storedEmailState = localStorage.getItem("email");
    const storedTimerState = localStorage.getItem("resendTimer");

    if (storedOtpState) {
      setOtpState(storedOtpState);
      setEmail(storedEmailState);
      setResendTimer(storedTimerState);
      if (storedTimerState > 0) {
        setResendButtonDisabled(true);
        startResendTimer(storedTimerState);
      }
    }
  }, []);

  React.useEffect(() => {
    if (
      otpState === "sending_otp" ||
      otpState === "otp_verification" ||
      otpState === "password" ||
      otpState === "Remember_Device"
    ) {
      saveToLocalStorage();
    }
    if (otpState === "Remember_Device") {
      clearInterval(intervalRef.current);
      setResendTimer(0);
      localStorage.removeItem("resendTimer");
    }
  }, [otpState]);

  const intervalRef = React.useRef(null);

  const startResendTimer = (timerInMinutes) => {
    setResendTimer(timerInMinutes);
    intervalRef.current = setInterval(() => {
      setResendTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
    setTimeout(() => {
      clearInterval(intervalRef.current);
      setResendButtonDisabled(false);
    }, timerInMinutes * 1000);
  };

  React.useEffect(() => {
    if (resendTimer) {
      localStorage.setItem("resendTimer", resendTimer);
    }
  }, [resendTimer]);

  React.useEffect(() => {
    if (OTP.length === 6) {
      handleVerify({ preventDefault: () => {} });
    }
  }, [OTP]);

  return (
    <div style={{ backgroundColor: "#fcf6f6", height: "100vh" }}>
      <LoginHeader />
      <ToastContainer />
      <CookiesProvider>
        <div className="loginBox">
          <h1>
            {otpState === "sending_otp"
              ? "Email Verification"
              : otpState === "otp_verification"
              ? "OTP Verification"
              : otpState === "Remember_Device"
              ? ""
              : ""}
          </h1>
          {otpState === "sending_otp" ? (
            <form onSubmit={validateEmail}>
              <input
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={(e) => {
                  setPass(e.target.value);
                }}
                value={pass}
                fullWidth={true}
                disableUnderline={true}
                endAdornment={
                  <InputAdornment position="end">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </div>
                  </InputAdornment>
                }
              />
              <span style={{ textAlign: "left", display: "block" }}>{msg}</span>
              <span
                style={{ color: "red", textAlign: "left", display: "block" }}
              >
                {error}
              </span>
              <Link to="/forgot_password" className="forgotPassword">
                Forgot Password?
              </Link>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button type="submit" onChange={otpState}>
                  Secure Sign In
                </button>
              </div>
            </form>
          ) : otpState === "otp_verification" ? (
            <div>
              <p>To protect your security, we need to verify your identity.</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  type="submit"
                  onClick={handleResend}
                  disabled={isDisable}
                >
                  Email me: {`${email}`}
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "20px",
                }}
                >
                  <button
                    onClick={() => setOtpState("sending_otp")}
                  >
                    Back to Login
                  </button>
                </div>
            </div>
          ) : otpState === "password" ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure={false}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <button
                  type="submit"
                  onClick={handleResend}
                  disabled={resendButtonDisabled || isDisable}
                  style={{
                    backgroundColor: resendButtonDisabled ? "grey" : "",
                  }}
                >
                  Resend OTP {resendButtonDisabled ? `(${resendTimer}s)` : ""}
                </button>
                <button type="submit" onClick={handleVerify}>
                  Verify
                </button>
              </div>
            </>
          ) : otpState === "Remember_Device" ? (
            <>
              <div>
                <h5>Access Code Accepted</h5>
                <button type="submit" onClick={notRememberDevice}>
                  Do Not Remember Device
                </button>
                <button type="submit" onClick={rememberDevice}>
                  Remember Device
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </CookiesProvider>
      <LoginFooter />
    </div>
  );
};

export default LoginPage;
