import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableHead,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";

const QueryTableContainer = ({
  Loader,
  allChecked,
  someChecked,
  handleParentChange,
  searchObject,
  setSearchObject,
  visitNames,
  queryLogData,
  checked,
  handleChildChange,
  convertFirstLetterToCapital,
  handleHyperLink,
  handleGenerateQueryMessage,
  setShowChatModal,
  handleGenerateQuery,
  setShowRolesModal,
  QuestionMarkRoundedIcon,
}) => {
  const [sortStatus, setSortStatus] = useState(false);
  const [queryStatusCount, setQueryStatusCount] = useState("0");
  const [sortedQueryLogData, setSortedQueryLogData] = useState([]);

  const handleSort = () => {
    setSortStatus((prev) => !prev);
    if (sortStatus) {
      const inCompleteQueries = queryLogData.filter(
        (item) => item?.query_status?.status === "InComplete"
      );
      const completeQueries = queryLogData.filter(
        (item) => item?.query_status?.status === "Complete"
      );
      const queryStatusFalseQueries = queryLogData.filter(
        (item) => item?.query_status === false
      );
      const notStartedQueries = queryLogData.filter(
        (item) => item?.status === "Not Started"
      );
      setSortedQueryLogData([
        ...inCompleteQueries,
        ...completeQueries,
        ...queryStatusFalseQueries,
        ...notStartedQueries,
      ]);
    } else {
      setSortedQueryLogData(queryLogData);
    }
  };

  useEffect(() => {
    const count = queryLogData.filter(
      (item) => item?.query_status?.status === "InComplete"
    ).length;
    setQueryStatusCount(`${count}`);

    if (sortStatus) {
      const inCompleteQueries = queryLogData.filter(
        (item) => item?.query_status?.status === "InComplete"
      );
      const completeQueries = queryLogData.filter(
        (item) => item?.query_status?.status === "Complete"
      );
      const queryStatusFalseQueries = queryLogData.filter(
        (item) => item?.query_status === false
      );
      const notStartedQueries = queryLogData.filter(
        (item) => item?.status === "Not Started"
      );
      setSortedQueryLogData([
        ...inCompleteQueries,
        ...completeQueries,
        ...queryStatusFalseQueries,
        ...notStartedQueries,
      ]);
    } else {
      setSortedQueryLogData(queryLogData);
    }
  }, [queryLogData]);

  return (
    <div className="patient_table patient_photos">
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          {Loader ? (
            <div className="loader">
              <img src="https://i.gifer.com/Yb3A.gif" alt="Loading" />
            </div>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead stickyHeader>
              <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey",
                      position: "sticky",
                      left: 0,
                      zIndex: 10,
                    }}
                    align="center"
                    colSpan={1}
                  >
                    Select All
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey",
                      position: "sticky",
                      left:  80,
                      zIndex: 10,
                    }}
                    align="center"
                    colSpan={2}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey"
                    }}
                    align="center"
                  ></TableCell>
                  <TableCell
                    sx={{ backgroundColor: "burlywood" }}
                    align="center"
                    colSpan={3}
                  >
                    CRC/INV/SUB-INV
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "mistyrose" }}
                    align="center"
                    colSpan={3}
                  >
                    CRO/DM CDQC
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "powderblue" }}
                    align="center"
                    colSpan={3}
                  >
                    INV eSignature
                  </TableCell>                  
                  <TableCell
                    sx={{
                      backgroundColor: "lightsteelblue",
                    }}
                    align="center"
                    colSpan={3}
                  >
                    DM Freeze
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey",
                      position: "sticky",
                      left: 0,
                      zIndex: 30,
                    }}
                    align="center"
                  >
                    <Checkbox
                      checked={allChecked}
                      indeterminate={!allChecked && someChecked}
                      onChange={handleParentChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey",
                      position: "sticky",
                      left: 70,
                      zIndex: 30,
                    }}
                  >
                    <FormControl
                      variant="filled"
                      style={{ width: "200px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <InputLabel> Visit Name</InputLabel>
                      <Select
                        sx={{
                          backgroundColor: "lightgrey",
                        }}
                        value={searchObject.visit}
                        onChange={(e) =>
                          setSearchObject((prev) => ({
                            ...prev,
                            visit: e.target.value,
                          }))
                        }
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {visitNames?.map((visit_name, index) => (
                          <MenuItem key={index} value={visit_name}>
                            {visit_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey",
                      position: "sticky",
                      left: 300,
                      zIndex: 30,
                    }}
                  >
                    <TextField
                      label="Block Name"
                      placeholder="Search by Block Name"
                      variant="filled"
                      style={{ width: "200px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={searchObject.block_name}
                      onChange={(e) =>
                        setSearchObject((prev) => {
                          return {
                            ...prev,
                            block_name: e.target.value,
                          };
                        })
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightgrey",
                    }}
                    align="center"
                  >
                    <TableSortLabel
                      active={sortStatus}
                      direction={"asc"}
                      onClick={handleSort}
                    >
                      <Typography variant="body2" display="inline">
                        Query Status
                      </Typography>
                    </TableSortLabel>
                    <Badge
                      badgeContent={queryStatusCount}
                      color="primary"
                      sx={{ marginLeft: 1 }}
                    ></Badge>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "burlywood" }}>
                    <FormControl
                      variant="filled"
                      style={{ width: "200px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      Block Status
                      <Select
                        value={searchObject.block_status}
                        onChange={(e) =>
                          setSearchObject((prev) => {
                            return {
                              ...prev,
                              block_status: e.target.value,
                            };
                          })
                        }
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem key={2} value={"Complete"}>
                          Complete
                        </MenuItem>
                        <MenuItem key={3} value={"InComplete"}>
                          InComplete
                        </MenuItem>
                        <MenuItem key={4} value={"Not Started"}>
                          Not Started
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "burlywood" }}
                    align="center"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "burlywood" }}
                    align="center"
                  >
                    Role
                  </TableCell>                  
                  <TableCell sx={{ backgroundColor: "mistyrose" }}>
                    <FormControl
                      variant="filled"
                      style={{ width: "200px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      Block Status
                      <Select
                        value={searchObject.cro_block_status}
                        onChange={(e) =>
                          setSearchObject((prev) => {
                            return {
                              ...prev,
                              cro_block_status: e.target.value,
                            };
                          })
                        }
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem key={2} value={"Complete"}>
                          Complete
                        </MenuItem>
                        <MenuItem key={3} value={"InComplete"}>
                          InComplete
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "mistyrose" }}
                    align="center"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "mistyrose" }}
                    align="center"
                  >
                    Date/Time timestamp
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "powderblue" }}>
                    <FormControl
                      variant="filled"
                      style={{ width: "200px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      Block Status
                      <Select
                        value={searchObject.inv_certified}
                        onChange={(e) =>
                          setSearchObject((prev) => {
                            return {
                              ...prev,
                              inv_certified: e.target.value,
                            };
                          })
                        }
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem key={2} value={"Done"}>
                          Done
                        </MenuItem>
                        <MenuItem key={3} value={"Not Done"}>
                          Not Done
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "powderblue" }}
                    align="center"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "powderblue" }}
                    align="center"
                  >
                    Date/Time timestamp
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightsteelblue",
                    }}
                  >
                    <FormControl
                      variant="filled"
                      style={{ width: "200px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      Block Status
                      <Select
                        value={searchObject.dm_block_status}
                        onChange={(e) =>
                          setSearchObject((prev) => {
                            return {
                              ...prev,
                              dm_block_status: e.target.value,
                            };
                          })
                        }
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem key={2} value={"Frozen"}>
                          Frozen
                        </MenuItem>
                        <MenuItem key={3} value={"UnFrozen"}>
                          UnFrozen
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightsteelblue",
                    }}
                    align="center"
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "lightsteelblue",
                    }}
                    align="center"
                  >
                    Freeze timestamp
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedQueryLogData.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow key={`empty-${index}`}>
                      <TableCell
                        sx={{
                          backgroundColor: "lightgrey",
                          position: "sticky",
                          left: 0,  
                       
                        }}
                        align="center"
                      >
                        {item.status !== "Not Started" ? (
                          <Checkbox
                            checked={
                              checked.length >= index + 1
                                ? checked[index]
                                : false
                            }
                            onChange={(event) =>
                              handleChildChange(event, index, item.id)
                            }
                          />
                        ) : (
                          <>-</>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "lightgrey",
                          position: "sticky",
                          left: 70,  
                         
                        }}
                        style={{ whiteSpace: "pre-wrap" }}
                        align="center"
                      >
                        {item.visit_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "lightgrey",
                          position: "sticky",
                          left: 300,  
                                   
                        }}
                        style={{ whiteSpace: "pre-wrap" }}
                        align="center"
                      >
                        {convertFirstLetterToCapital(`${item.block_name}`)}
                        <OpenInNewIcon
                          style={{
                            color: "blue",
                            marginRight: "5px",
                          }}
                          onClick={() => handleHyperLink(item)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {item.status === "Not Started" ? (
                          "-"
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm m-2"
                            onClick={() => {
                              if (item.query_status?.id) {
                                handleGenerateQueryMessage(item);
                                setShowChatModal(true);
                              } else {
                                handleGenerateQuery(item);
                                setShowRolesModal(true);
                              }
                            }}
                          >
                            {item.query_status?.id ? (
                              item.query_status?.status === "Complete" ? (
                                <CheckSharpIcon
                                  style={{
                                    color: "blue",
                                    marginRight: "5px",
                                  }}
                                />
                              ) : (
                                <QuestionMarkRoundedIcon />
                              )
                            ) : (
                              "Generate a Query"
                            )}
                          </button>
                        )}
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "pre-wrap" }}
                        align="center"
                      >
                        <span
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {item.status === "Completed" && (
                            <CheckSharpIcon
                              style={{
                                color: "blue",
                                marginRight: "5px",
                              }}
                            />
                          )}
                          {item.status === "Incomplete" && (
                            <>
                              <CheckSharpIcon
                                style={{
                                  color: "blue",
                                  marginRight: "5px",
                                }}
                              />
                              <PriorityHighIcon
                                style={{
                                  color: "red",
                                  marginRight: "5px",
                                }}
                              />
                            </>
                          )}
                          {item.status === "Not Started" ? "-" : ""}
                        </span>
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "pre-wrap" }}
                        align="center"
                      >
                        {item.email ? item.email : "-"}
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "pre-wrap" }}
                        align="center"
                      >
                        {item.role ? item.role : "-"}
                      </TableCell>                      
                      <TableCell align="center">
                        {item.status_object?.cro_block_status_bool
                          ? "Complete"
                          : "InComplete"}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.cro_block_status
                          ? item.status_object?.cro_block_status
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.cro_block_status &&
                        item.status_object?.cro_block_status_datetime ? (
                          <>
                            {new Date(
                              item.status_object?.cro_block_status_datetime +
                                "Z"
                            ).toDateString()}
                            ,&nbsp;
                            {new Date(
                              item.status_object?.cro_block_status_datetime +
                                "Z"
                            ).toLocaleTimeString()}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.inv_certified_bool
                          ? "Done"
                          : "Not Done"}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.inv_certified
                          ? item.status_object?.inv_certified
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.inv_certified &&
                        item.status_object?.inv_certified_datetime ? (
                          <>
                            {new Date(
                              item?.status_object?.inv_certified_datetime + "Z"
                            ).toDateString()}
                            ,&nbsp;
                            {new Date(
                              item?.status_object?.inv_certified_datetime + "Z"
                            ).toLocaleTimeString()}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.dm_freeze_bool
                          ? "Frozen"
                          : "UnFrozen"}
                      </TableCell>
                      <TableCell>
                        {item.status_object?.dm_freeze
                          ? item.status_object?.dm_freeze
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item.status_object?.dm_freeze &&
                        item.status_object?.dm_freeze_datetime ? (
                          <>
                            {new Date(
                              item.status_object?.dm_freeze_datetime + "Z"
                            ).toDateString()}
                            ,&nbsp;
                            {new Date(
                              item.status_object?.dm_freeze_datetime + "Z"
                            ).toLocaleTimeString()}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

export default QueryTableContainer;
