import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import useUser from "../../hooks/user";

const TrialCrc = ({type, setShowModal, toast, onAddSuccess, handleAddReviewer, trialDetails, onReviewerChange, allReviewers, setAllReviewers, setAllInvestigators, allInvestigators, onInvestigatorChange, setInvestigatorChanged, setReviewerChanged}) => {
  const user = useUser();
  const token = localStorage.getItem("token");
  const [IsLoadingButton, setIsLoadingButton] = useState(false);
  const [newData, setNewData] = useState({});
  const [addStaff, setAddStaff] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddStaff((addStaff) => ({
      ...addStaff,
      [name]: value,
    }));
  };
  const Update = (e) => {
    e.preventDefault();
    setIsLoadingButton(true)
    let url;
    if(type === 'investigator'){
      url = 'add_investigator/'
    } else if(type === 'reviewer'){
      url = 'practitioners/add_reviewer/'
    }
    axios
      .post(`${__API_URL__}/api/${url}`, addStaff, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const reponseData = res.data;
        setShowModal(false)
        setAddStaff(false)
        setIsLoadingButton(false)
                toast.success(`${type === 'reviewer' ? 'Reviewer' : 'Investigator'} Added successfully`);
        if(type === 'investigator'){
          const filteredOptions = allInvestigators.filter(
            (option) => option.value !== reponseData.value
          );

          onInvestigatorChange([...trialDetails.investigators, reponseData], { action: 'select-option' });
          setAllInvestigators(filteredOptions);
          setInvestigatorChanged(true)

        } else if (type === 'reviewer'){
          const filteredOptions = allReviewers.filter(
            (option) => option.value !== reponseData.value
          );
          onReviewerChange([...trialDetails.reviewers, reponseData], { action: 'select-option' });
          setAllReviewers(filteredOptions);
          setReviewerChanged(true)
        }
      }).catch((err) => {
        setIsLoadingButton(false)
        toast.error(err?.response?.data?.message)
      });
  };
  return (
    <div>
      <Modal size="md" show onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
        <form
            onSubmit={(e) => Update(e)}>
            <>
              <div class="form-group">
                <label for="first_name">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="first_name"
                  name="first_name"
                  aria-describedby="emailHelp"
                  value={addStaff?.first_name}
                  onChange={handleChange}
                  placeholder="first name"
                  required
                />
              </div>
              <div class="form-group">
                <label for="last_name">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="last_name"
                  name="last_name"
                  value={addStaff?.last_name}
                  onChange={handleChange}
                  placeholder="last name"
                  required
                />
              </div>
              <div class="form-group">
                <label for="email_custom">Email</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  value={addStaff?.email}
                  onChange={handleChange}
                  placeholder="email"
                  required
                />
              </div>
                <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
                    Cancel
                </button>



                <button type="submit"
                    className={`btn btn-primary m-4${IsLoadingButton ? " btn-loading" : ""}`}
                    disabled={IsLoadingButton}
                    >
                    {
                        IsLoadingButton ? <><i class="fa fa-spinner fa-spin"></i> Loading</> :  "Save & Invite"
                    }
            </button>








                </Modal.Footer>
                </>
                </form>
            </Modal.Body>
        </Modal>
    </div>
  );
};
export default TrialCrc;
