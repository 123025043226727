import Modal from "react-bootstrap/Modal";
const QueryTablePopUp = (props) => {
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  return (
    <>
      {
        <>
          <Modal
            size="xxl"
            show={props.showQueryTableModal}
            fullscreen={true}
            onHide={() => props.setShowQueryTableModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Query Table
              </Modal.Title>
            </Modal.Header>
            <br />
            <div className="col-md-12 text-end">
              <button
                type="button"
                class="btn btn-primary btn-sm mx-5"
                onClick={() => props.setShowModal(true)}
                disabled={["Inv_coordinator", "Sub_investigator"].includes(
                  role
                )}
              >
                Update Status
              </button>
            </div>
            <Modal.Body className="show-grid">{props.children}</Modal.Body>
          </Modal>
        </>
      }
    </>
  );
};
export default QueryTablePopUp;
